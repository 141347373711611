require('../images/logo.png');
require('../images/favicon.png');
require('../images/brochure.png');
require('../images/main-banner-01.png');
require('../images/main-banner-02.jpg');
require('../images/main-banner-03.jpg');
require('../images/main-banner-04.png');
require('../images/proyecto-banner-01.jpg');
require('../images/proyecto-banner-02.jpg');
require('../images/proyecto-banner-03.jpg');
require('../images/equipo-alejandro-jara.jpg');
require('../images/equipo-alexis-jara.jpg');
require('../images/equipo-antonio-jara.png');
require('../images/equipo-cristobal-araya.jpg');
require('../images/equipo-felipe-jara.jpg');
require('../images/equipo-guido-guinez.jpg');
require('../images/equipo-jorge-jara.jpg');
require('../images/equipo-rafael-ocariz.jpg');
require('../images/logo-avantus.png');
require('../images/logo-b2gold.webp');
require('../images/logo-besalco.svg');
require('../images/logo-brotec.png');
require('../images/logo-candelaria.png');
require('../images/logo-codelco.png');
require('../images/logo-guanaco.jpg');
require('../images/logo-hatch.png');
require('../images/logo-hmc.png');
require('../images/logo-kinross.png');
require('../images/logo-knigh-piesold.svg');
require('../images/logo-las-cenizas.png');
require('../images/logo-mineria-activa.png');
require('../images/logo-mvc.png');
require('../images/logo-ncl.png');
require('../images/logo-nyrstar.svg');
require('../images/logo-hochschild.png');
require('../images/logo-tec-pro-min.png');
require('../images/logo-petaquilla.jpg');
