import $ from 'jquery';
import WOW from 'wow.js';
import 'popper.js';
import 'bootstrap';
import './_images';

new WOW().init();

$(() => {
    'use strict';

    const $nav = $('nav');
    const $navItems = $('.navbar-nav .nav-item');
    const $anchors = $('.goto-anchor');
    const $buttonDownload = $('#button-download');
    const $linkDownload = $('#link-download');
    const $inputEmail = $('#email');
    const sticky = $nav.height();
    const url = window.location.toString();
    const urlAnchorIndex = url.indexOf('#');

    function gotoAnchor($target) {
        if ($target.length) {
            $('html, body').animate({
                scrollTop: $target.offset().top - sticky
            });
        }
    }

    const navbarCollapse = function () {
        if ($nav.offset().top > sticky) {
            $nav.addClass('sticky');
        } else {
            $nav.removeClass('sticky');
        }
    };

    navbarCollapse();

    $(window).scroll(navbarCollapse);

    if (-1 < urlAnchorIndex) {
        gotoAnchor(url.substring(urlAnchorIndex));
    }

    $anchors.click(function (event) {
        event.preventDefault();

        gotoAnchor($(this.hash));

        $navItems.each(function () {
            $(this).removeClass('active');
        });

        $(this).closest('.nav-item').addClass('active');
    });

    $buttonDownload.click(function () {
        let email = $inputEmail.val().trim();

        if ('' === email) {
            Swal.fire({
                title: '¡Atención!',
                text: 'Por favor ingresa un correo válido.',
                icon: 'error',
                confirmButtonText: 'OK'
            });

            return;
        }

        $buttonDownload.attr('disabled', 'disabled');

        $.ajax({
            url: 'https://www.ajgingenieria.cl/brochure/index.php',
            method: 'post',
            data: {email: email},
        }).done(function () {
            Swal.fire({
                title: '¡Gracias!',
                text: 'En breve comenzará la descarga de nuestro Brochure.',
                icon: 'success',
                confirmButtonText: 'OK'
            });

            $linkDownload.get(0).click();
            $inputEmail.val('');
        }).fail(function (jqXHR) {
            let status = jqXHR.status;

            if (404 === status || 400 === status) {
                Swal.fire({
                    title: '¡Atención!',
                    text: 'Por favor ingresa un correo válido.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else {
                Swal.fire({
                    title: 'Error',
                    text: 'No pudimos enviar el brochure, por favor vuelve a intentar mas tarde.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        }).always(function () {
            $buttonDownload.removeAttr('disabled');
        });
    });
});
